import type {Dictionary} from "lodash";
import {ConnectionRole, EntityAccessStatus, Profile, RoleType} from "../enums";
import {ConnectionRoles} from "../enums/connections";
import {
  PhoneNumberType,
  SecondaryEmailType,
  TwoFactorMethod,
  UserChangeReason,
  ValidationOptionType,
} from "../enums/users";
import {Permission} from "../permissions";
import {JSONObject} from "../utils";
import {InternalBadge} from "./badge";
import type {DataShareUserData} from "./datashare";
import type {ActiveEntity, BasicEntityInfo, UserEntityRole} from "./entity";
import type {Pagination} from "./index";
import type {Kit} from "./kit";
import type {Role} from "./role";

export interface UserLocale {
  timezone?: string;
  language?: string;
  dateFormat?: string;
  timeFormat?: string;
  groupSeparator?: string;
  decimalSeparator?: string;
}

export interface UserCommPreferences {
  _id?: string;
  digest: boolean;
  messages: boolean;
  tasks?: boolean;
}

export interface PhoneNumber {
  phoneNumber: string;
  type: PhoneNumberType;
  verified: boolean;
  country: string;
}

export interface SecondaryEmail {
  email: string;
  type: SecondaryEmailType;
  verified: boolean;
}

export interface TwoFactorAuth {
  method: TwoFactorMethod;
  verified: boolean;
  optionMask?: string;
}

export interface UserPatch {
  email?: string;
  name?: string;
  locale?: string;
  localeSettings: UserLocale;
  communicationPreferences: UserCommPreferences;
  secondaryEmails?: SecondaryEmail[];
  casualExperienceUserAcknowledged?: boolean;
  title?: string;
  country?: string;
  phoneNumber?: PhoneNumber;
  fax?: PhoneNumber;
}

export interface Set2FAPhoneNumberPatch {
  phoneNumber: PhoneNumber;
  tryProve: boolean;
}

export interface UserPasswordChange {
  password: string;
  oldPassword?: string;
}

export interface EntityAssociationInfo {
  entityId: string;
  status: string;
  info?: BasicEntityInfo;
  activeConnectionRole: string;
  defaultConnectionRole: string;
  supportUser: boolean;
  roleIds: string[];
  title?: string;
  ssoAttributes?: {[key: string]: any};
}

export interface EntityAccessRequestData {
  requestSentDate: Date;
  assignee?: UserAvatar;
}

export interface UserEntities {
  entityId: string;
  status: EntityAccessStatus;
  roles: Role[];
  entityName: string;
  entityLogo: string;
  activeConnectionRole: ConnectionRole;
  defaultConnectionRole: ConnectionRoles;
  accessRequestData?: EntityAccessRequestData;
}

export interface InvitationDetails {
  connection: string;
  entity: string;
}

export interface BasicUser {
  _id: string;
  email: string;
  locale: string;
  displayName: string;
  imageThumbnailUrl?: string;
  imageFileId?: string;
  activeEntityId?: string;
  activeEntityInfo?: BasicEntityInfo;
  activeEntityAdminDomains: string[];
  activeConnectionRole?: ConnectionRole;
  activeEntityActiveConnectionRole: ConnectionRole | undefined;
  activeEntityIsResponderOnly: boolean;
  entities: EntityAssociationInfo[];
  availableConnectionRoles: UserEntityRole[];
  activeEntities: ActiveEntity[];
  localeSettings: UserLocale;
  communicationPreferences: UserCommPreferences;
  lastLogin: Date | null;
  permissions: Permission[];
  topicOwnerRoles: string[];
  twoFactorAuthVerified: boolean;
  twoFactorAuth?: TwoFactorAuth;
  identityVerified: boolean;
  needsIdentityVerified?: boolean;
  treatUserAsIdentityVerified: boolean;
  limitedVisibility?: boolean;
  securityRoles: string[];
  isDataShareUser: boolean;
  dataShareData?: DataShareUserData;
  activeEntityRoles: string[];
  kits: Kit[];
  internalBadges: InternalBadge[];
  casualExperienceUserAcknowledged?: boolean;
  invitationDetails?: InvitationDetails;
  title?: string;
  country?: string;
  phoneNumber?: PhoneNumber;
  fax?: PhoneNumber;
  isGiactReady?: boolean;
  isGiactEnable?: boolean;
  userCanExport?: boolean;
  ssoAttributes?: {[key: string]: any};
  searchColumns?: CustomSearchColumns;
}

export interface CustomSearchColumns {
  [key: string]: string[];
}

export interface UserAvatar {
  _id: string;
  displayName: string;
  imageThumbnailUrl?: string;
  email: string;
  createdAt?: Date | string;

  // these fields are (currently) exclusive to the Go Question API and even then, they may be undefined
  titles?: Dictionary<string>; // titles by entityId (may be trimmed to only return relevant entities)
  lastLogin?: string;
  phoneNumbers?: PhoneNumber[];
  deleted?: boolean;
}

export interface UserProfile {
  displayName: string;
  gender?: string;
  imageFile?: string;
  firstName?: string;
  lastName?: string;
}

export interface UserEditProfile extends BasicUser {
  password: string;
  password2: string;
  title: string;
  country: string;
  phoneNumber: PhoneNumber;
  fax: PhoneNumber;
  ssoAttributes?: {[key: string]: string};
}

export interface UserWithProfile {
  _id: string;
  profile: UserProfile;
  email: string;
  id: string;
}

export interface UserAvatarLookUpTable {
  [k: string]: UserAvatar;
}

export interface SignInJWT {
  token: string;
  tokenExpiresOn: number; // number of seconds from 1970-01-01T00:00:00Z UTC
}

export interface SignInJWTPayload {
  sub: string; // userId
  iss: string;
  iat: number;
  entity: string;
  tfa: boolean; // two factor authentication
  ver: number;
  dataShareId?: string;
}

export interface SignInResponse extends SignInJWT {
  user: BasicUser;
  passwordUnset: boolean;
}

export interface Set2FAPhoneNumberResponse {
  verificationLinkWasSent: boolean;
  proveScoreId?: string;
}

export interface CanCreateEntityResponse {
  userCanCreateEntity: boolean;
}

export type TwoFaNonProfileSection = "admin" | "personal";

// This enum uses numbers which get bigger as the permission gets more powerful.
export enum TwoFaTopicPermission {
  VIEW = 1,
  EDIT = 2,
}

export interface IdentityVerificationStatusRequest {
  connectionId: string;
  currentProfile: Profile;
  nonProfileSection: TwoFaNonProfileSection;
  securedForApproval: boolean;
  editMode: boolean;
  // The question-related fields: only one of these should be sent at a time:
  topicId: string;
  securityRoles: string[];
  serverSideTopicIds: string[];
}

export interface IdentityVerificationStatusResponse {
  isProveEnabled: boolean;
  isVeriffEnabled: boolean;
  showContactSupportWarning: boolean;
  limitVeriffToTestUsers?: boolean;
  userIdentityVerified: boolean;
  treatUserAsIdentityVerified: boolean;
  veriffAllowedPolicy: boolean;
}

export interface TwoFactorOptions {
  options: ValidationOption[];
  otp: {url: string; secret: string};
}

export interface VerificationResult {
  verificationFailed?: boolean;
  verificationExpired?: boolean;
  entityAssociationNotFound?: boolean;
  loginRequired?: boolean;
  ssoURL?: string;
  signInResponse?: SignInResponse;
  entityId?: string;
}

export interface UserSortOptions {
  name?: number;
  status?: number;
  activeConnectionRole?: number;
  _id?: number;
}

export interface GetUserOptions extends Pagination {
  userId?: string;
  userIds?: string[];
  excludeIds?: string[];
  searchText?: string;
  userPageSearchText?: string;
  sort?: UserSortOptions;
  withRoleIds?: string[];
  withConnectionRole?: ConnectionRole;
  associationStatus?: EntityAccessStatus;
  associationStatuses?: EntityAccessStatus[];
  includeAdminCount?: boolean;
  forTaskId?: string;
  limit?: number;
  getAllUsers?: boolean;
  includeSupportUsers?: boolean;
  applySegmentation?: boolean;
  mustHaveAllRoles?: boolean;

  connectionId?: string;
  kitType?: string;
  kitId?: string;
  answers?: JSONObject;
  emailAddress?: string;
  nonNetworkEntityId?: string;
  fieldsForUserOptions?: boolean; // project as little as possible in the query
  returnNameOnly?: boolean; // project as little as possible in the query

  queryConnectionCounterparty?: boolean;
  ignoreSort?: boolean;
}

export interface GetAllUsersOptions extends Pagination {
  search?: string;
  page?: number;
  sort?: UserSortOptions;
  limit?: number;
  deleted?: string;
  hasGraphiteAdminRole?: boolean;
}

export interface ListUser {
  name: string;
  email: string;
  _id: string;
  lastLogin?: string;
  createdAt: string;
  updatedAt: string;
  verified?: boolean;
  identityVerified?: boolean;
  needsIdentityVerified?: boolean;
  deleted?: boolean;
  imageFileId?: string;
  externalId?: string;
  roles: Role[];
  tags: string[];
  status: EntityAccessStatus;
  activeConnectionRole?: ConnectionRole;
  defaultConnectionRole?: ConnectionRole;
  locale?: string;
  entities?: EntityAssociationInfo[];
  phoneNumbers?: PhoneNumber[];
  faxes?: PhoneNumber[];
  title?: string;
  country?: string;
  ssoAttributes?: {[key: string]: string};
  ssoRoles?: string[];
}

export type ListUserLookUpTable = Dictionary<ListUser>;

export interface UserResults {
  total: number;
  users: ListUser[];
  numberOfAdmins?: number;
}

export interface ListUserForSupport extends ListUser {
  proveCount?: number;
  veriffCount?: number;
  manualCount?: number;
  ssoCount?: number;
}

export interface UserResultsForSupport {
  total: number;
  users: ListUserForSupport[];
}

export interface UpdateSSOAttributes {
  userId: string;
  ssoAttributes?: {[key: string]: string};
}

export interface UpdateUser {
  name?: string;
  email?: string;
  phoneNumber?: PhoneNumber;
  originalPhoneNumber?: PhoneNumber;
  faxNumber?: PhoneNumber;
  originalFaxNumber?: PhoneNumber;
  title?: string;
  country?: string;
  roles?: string[];
  tags?: string[];
  activeConnectionRole?: string;
  defaultConnectionRole?: string;
  segmentUpdates?: RoleSegmentUpdate[];
  externalId?: string;
  connectionId?: string;
  nonNetworkEntityId?: string;
  isNobody?: boolean;
  ssoAttributes?: {[key: string]: any};
}

export interface UserSearchColumnsUpdate {
  searchColumns: string[];
  type: string;
}

export interface UserSegmentUpdate {
  userId: string;
  segmentationEnabled?: boolean;
  userSegmentList?: SegmentUpdate[];
  noChange?: boolean;
}

export interface RoleSegmentUpdate {
  roleId: string;
  segmentationEnabled?: boolean;
  userSegmentList?: SegmentUpdate[];
  deleteUserRoleSegment?: boolean;
}

export interface SegmentUpdate {
  questionId: string;
  allSelected?: boolean;
  options?: string[];
}

export interface InviteUser {
  name: string;
  email: string;
  title?: string;
  phone?: PhoneNumber;
  fax?: PhoneNumber;
  country?: string;
  entity?: string;
  isNobody?: boolean;
  nonNetworkEntityId?: string;
}

export interface UpdateAssociationStatus {
  entityId: string;
  status: string;
}

export interface ValidationOption {
  type: ValidationOptionType;
  optionMasked: string;
  optionHash: string;
}

export interface AuditUserInfo {
  _id: string;
  name: string;
  email: string;
}

export interface GraphiteQuestionSuggestionInput {
  question: string;
}

export interface ChangeLog {
  reason: UserChangeReason;
  changedAt: string;
  changedBy: AuditUserInfo;
}

export interface APISegmentationData {
  question: string;
  options?: string[];
  allSelected?: boolean;
}

export interface ApiUserRole {
  _id: string;
  name: string;
  segments?: APISegmentationData[];
}

export interface APIUser {
  _id: string;
  email: string;
  verified: boolean;
  locale?: string;
  name?: string;
  connectionRole?: ConnectionRole;
  lastLogin?: string;
  changeLogs?: ChangeLog[];
  roles: ApiUserRole[];
  status: EntityAccessStatus;
}

export interface APIRole {
  _id: string;
  name: string;
  key: string;
  description: string;
  type: RoleType;
  segmentationOn: boolean;
}

export interface SimpleUserInfo {
  value: string;
  name: string;
  email: string;
  createdAt: Date | string;
  deleted: boolean | undefined;
}

export interface InviteUrlParams extends JSONObject {
  email: string;
  name?: string;
  entityName?: string;
  senderName?: string;
  newName?: string;
  inviteToken?: string;
  entity?: string;
}

export interface UpdateUserPreferences {
  userName: string;
  userId: string;
  userDigest: string;
  userMessages: string;
  userTask: string;
  userTimeZone: string;
  userLanguage: string;
}

export interface VerificationStatusParams {
  userEmails: string[];
  connectionId?: string;
  currentProfile?: Profile;
}

export interface VerificationStatus {
  userId: string;
  email: string;
  deleted?: boolean;
  identityVerified?: boolean;
  needsIdentityVerified?: boolean;
  telecomVerification: boolean | null;
  idVerification: boolean | null;
  manualVerification?: boolean;
  ssoVerification?: boolean;
}
